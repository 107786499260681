<template>
  <div class="lk-container">
    <div class="work-window">
      <LKNavigationPanel />
      <div class="lk-work-window scroll">
        <LKWindow />
      </div>
    </div>
  </div>
</template>

<script setup>
import LKWindow from "@/pages/LKPage/LKWindow.vue";
import LKNavigationPanel from "@/pages/LKPage/LKNavigationPanel.vue";
import {onMounted} from "vue";
import {useStore} from "@/store";

const store = useStore();

onMounted(() => {
  const localStorageTab = localStorage.getItem("lk_tab");
  if (localStorageTab) {
    store.showMode = localStorageTab;
  } else {
    store.showMode = "projects";
    localStorage.setItem("lk_tab", "projects");
  }
});
</script>

<style lang="scss">
@import '@/assets/styles/styles.scss';

.lk-container {
  background-color: $background-color;
  box-sizing: border-box;
  position: relative;
  min-width: 710px;
}

.work-window {
  display: flex;
  justify-content: space-between;
  //padding: 10px;
  box-sizing: border-box;
  gap: 14px;
  min-height: 700px;
  height: calc(100vh - 92px);
}

.lk-work-window {
  background-color: $background-color-content;
  border-radius: 10px;
  display: grid;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}
</style>