export const createProjectForm = {
    id: null,
    name: "",
    description: "",
    modules: ["module_globus"],
    mapFiles: [],
    users: [],
    countryId: null,
    isArchive: false,
    isOwner: true
};