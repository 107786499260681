import { defineStore } from "pinia";

const devModuleList = ["module_globus"];
const prodModuleList = ["module_globus"];

const isProd = window.location.port.length === 0 || window.location.port === "8888";

export const useStore = defineStore({
    id: "store",
    state: () => ({
        isProd: window.location.port.length === 0 || window.location.port === "8888",
        loader: false,
        notifications: [],
        showType: "login", // login close open
        activeModule: { name: "Глобус", dataName: "module_globus"},
        routing: [],
        mainHrefApp: "",
        showMode: localStorage.getItem("lk_tab") || "projects",
        language: {code: "ru"},
        isShowLogoutDialog: false, // Диалог входа/выхода
        isShowProjectNoModuleDialog: false // Диалог, если в активном проекте нет текущего модуля
    }),
    actions: {
        getEnabledModuleList() {
          return isProd ? prodModuleList : devModuleList;
        },
        createNotify(mess, type) {
            this.notifications.push({
                message: mess,
                type: type,
                color: type === "success" ? "green" : "red",
                lifeTime: type === "success" ? 5000 : 5000
            });
        },
    }
});
