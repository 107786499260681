<template>
  <div
    v-if="!item.disabled"
    class="routing__item"
    :class="{'routing__item_active': activeRoute === item.route}"
  >
    <img
      v-if="activeRoute === item.route"
      :src="getImageUrl(item.icon + '-active')"
      alt=""
      @mouseenter="isShowTooltip = true"
      @mouseleave="isShowTooltip = false"
    >
    <img
      v-else
      :src="getImageUrl(item.icon + '-disabled')"
      alt=""
      @click="changeRoute(item.route)"
      @mouseenter="isShowTooltip = true"
      @mouseleave="isShowTooltip = false"
    >

    <div
      v-if="isShowTooltip"
      class="router-tooltip"
    >
      {{ t('router_item.' + item.id) }}
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import { t } from "@/controllerLK/GlobusLocalization";
import facebook_active from "@/assets/icons/facebook-active.png";
import facebook_disabled from "@/assets/icons/facebook-disabled.png";
import instagram_active from "@/assets/icons/instagram-active.png";
import instagram_disabled from "@/assets/icons/instagram-disabled.png";

defineProps({
  item: {
    type: Object,
    required: true,
  }
});

const getImageUrl = (name) => {
  switch (name) {
    case "facebook-active":
      return facebook_active;
    case "facebook-disabled":
      return facebook_disabled;
    case "instagram-active":
      return instagram_active;
    case "instagram-disabled":
      return instagram_disabled;
  }
};

const router = useRouter();
const activeRoute = computed(() => router.currentRoute.value.fullPath);
const isShowTooltip = ref(false);

const changeRoute = (route) => {
  router.push(route); 
};
</script>

<style lang="scss" scoped>
.router-tooltip {
  position: absolute;
  top: 50px;
  left: 0;
  padding: 5px 10px;
  color: black;
  border-radius: 10px;
  background: white;
  white-space: nowrap;
}

.routing__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: .2s;
  border: none;
  border-radius: 50%;
  background: none;

  & img {
    width: 30px;
    height: 30px;
  }
}
</style>