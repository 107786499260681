<script setup>
import NotificationItem from "@/components/Notification/NotificationItem.vue";
import {useStore} from "@/store";
const store = useStore();
</script>

<template>
  <div class="notification-block">
    <TransitionGroup name="list">
      <NotificationItem
        v-for="item in store.notifications"
        :key="item"
        :item="item"
      />
    </TransitionGroup>
  </div>
</template>

<style scoped lang="scss">
.notification-block {
  position: fixed;
  z-index: 5000;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>