import {defineStore} from "pinia";
import axios from "axios";
import {setUser} from "@/composables/ModuleCommunication";

export const useUser = defineStore({
    id: "userStore",
    state: () => ({
        user: {
            userName: "",
            roleId: "",
            roleNames: [],
            modules: [],
            userId: "",
            firstName: "",
            middleName: "",
            lastName: "",
            activeProject: 0,
            countryId: "",
            email: "",
            phone: "",
            roleName: "",
            image: "",
            language: ""
        },
        organizationMembers: []
    }),
    actions: {
        getFullName() {
            if (this.user.lastName === null) { this.user.lastName = ""; }
            if (this.user.firstName === null) { this.user.firstName = ""; }
            if (this.user.middleName === null) { this.user.middleName = ""; }

            return `${this.user.lastName} ${this.user.firstName} ${this.user.middleName}`;
        },

        async changeUserLanguage(lang) {
            let response;
            await axios.put("/globus/api/User/language", lang,
                {headers: {"Content-Type": "application/json"}}).then(res => {
                response = res;
            });
            return response;
        },

        async updateUser(data) {
            await axios.patch("/globus/api/User/profile", data).then(async () => {
                await this.getUser();
            }).catch((error) => {
                console.log(error);
                throw error;
            });
        },

        async getUser(){
            try {
                const result = await axios.get("/globus/api/User/settings");
                this.user = result.data;
                setUser(this.user);
            } catch (e) {
                console.log(e);
                return false;
            }
            return this.user;
        },
        clearUser() {
            setUser(""); // очистка юзера в приложение
            this.organizationMembers = [];
            this.user = ""; // очистка юзера в ЛК
        },

        async getOrganizationMembers() {
            try {
                const result = await axios.get("/globus/api/User/organization/members");
                this.organizationMembers = result.data;
            } catch (e) {
                console.log(e);
                return false;
            }
            return this.organizationMembers;
        },
    }
});
