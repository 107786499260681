import {getToken} from "@/composables/auth";
import {exit, serverErrorExit} from "@/composables/functionExit";
import {changeLoadApp, changeShow, goLkPage, goMainPage, setProject} from "@/composables/ModuleCommunication";
import {projectDisabledModules} from "@/composables/constans/modules";
import {useUser} from "@/store/user";
import {useProjects} from "@/store/projects";
import {useStore} from "@/store";
import {useGlobusLocal, t} from "@/controllerLK/GlobusLocalization";

import {ProjectHasntModule} from "@/router";
import { router } from "@/router/index";

export const personalAreaInitialize = async () => {

    const store = useStore();
    const userStore = useUser();
    const projectStore = useProjects();
    const { initGlobusLocal } = useGlobusLocal();

    try {
        const token = await getToken();

        if (token) {
            const currentUser = await userStore.getUser();
            const organizationMembers = await userStore.getOrganizationMembers();

            if (!currentUser || !organizationMembers) {
                await serverErrorExit();
                store.createNotify(t("login_page.error.server"), "error");
                return false;
            }

            if (!userStore.user.modules.find(item => item.moduleId === store.activeModule.dataName)
                && !["module_proxy", "module_sources"].includes(store.activeModule.dataName) ) {
                await exit();
                store.createNotify("У данного пользователя нет доступа к модулю, обратитесь к администратору", "error");
                store.loader = false;
                return;
            }

            if (userStore.user.language.toLowerCase() !== localStorage.getItem("globusLanguage")) {
                localStorage.setItem("globusLanguage", userStore.user.language.toLowerCase());
                window.location.reload();
            }

            initGlobusLocal(userStore.user.language.toLowerCase(), "personal_area");

            if (userStore.user.activeProject) {
                await projectStore.getProjects();

                let project = await projectStore.getProject(userStore.user.activeProject);

                if (project) {
                    projectStore.activeProject = project;
                    setProject(project);

                    changeLoadApp(true);
                    changeShow(true);


                    if (projectStore.activeProject.modules
                        && !projectStore.activeProject?.modules.includes(store.activeModule.dataName)
                        && !projectDisabledModules.includes(store.activeModule.dataName)) {
                        await ProjectHasntModule();
                    }
                }
            } else if (userStore.user.modules.some((module) => module.moduleId === "module_globus")) {
                await projectStore.getProjects();
            } else {
                changeLoadApp(false);
                changeShow(false);
            }

            if (!userStore.user.activeProject || projectStore.projects.length === 0) {
                store.createNotify(t("toast.error.no_project"), "error");
                localStorage.setItem("lk_tab", "projects");
                await goLkPage();
            }
        }
    } catch(e){
        console.log(e);
    }
    store.loader = false;
};