<script setup>
import {t} from "@/controllerLK/GlobusLocalization";
import {ref} from "vue";
import SvgIcon from "@/components/common/svgButton.vue";
import {useProjects} from "@/store/projects";
import BlockView from "@/components/common/BlockView.vue";

const projectStore = useProjects();
const emit = defineEmits(["closeRegionSearch", "addRegionToList"]);

const searchString = ref("");
const isShowDropdown = ref(false);
const selectedRegion = ref(null);

const searchRegion = (value) => {
  if (value === selectedRegion.value?.name) {
    projectStore.searchRegion(value);
    isShowDropdown.value = false;
  } else {
    if (value.length === 0) {
      isShowDropdown.value = false;
      projectStore.foundedRegionList = [];
    } else {
      projectStore.searchRegion(value);
      isShowDropdown.value = true;
    }
  }
};

const addRegionToList = (region) => {
  if (!region) {
    isError.value = true;
    return false;
  }
  isShowDropdown.value = false;
  searchString.value = "";
  projectStore.foundedRegionList = [];
  emit("closeRegionSearch");
  emit("addRegionToList", region);
};

const isError = ref(false);
</script>

<template>
  <div class="wrapper">
    <div class="globus-combo-box">
      <input
        v-model="searchString"
        class="globus-combo-box__input"
        :class="{'globus-combo-box__input_error': isError}"
        type="text"
        :placeholder="t('project_modal_country.placeholder.map_input')"
        @input="searchRegion($event.target.value)"
        @focusin="isShowDropdown = true"
      >
      <SvgIcon
        class="globus-combo-box__icon"
        :class="{'globus-combo-box__icon_active': isShowDropdown}"
        name="search"
        width="14px"
        height="14px"
      />
    </div>
    <div
      v-if="isShowDropdown && projectStore.foundedRegionList?.length > 0"
      class="globus-combo-box__dropdown scroll"
    >
      <div
        v-for="item in projectStore.foundedRegionList"
        :key="item"
        class="dropdown-item"
        @click="addRegionToList(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>

  <BlockView
    v-if="isShowDropdown"
    @click="isShowDropdown = false"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars';
@import '@/assets/styles/classes.scss';

input {
  all: unset;
}

.wrapper {
  width: 100%;
  position: relative;

  .globus-combo-box {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    padding: 8px 12px;

    &:hover {
      background: #E0F0FF;
      border: 1px solid #E0F0FF;
    }

    &__icon {
      color: #CACACA;

      &_active {
        color: #1F9AFF;
      }
    }

    &__input {
      padding-left: 5px;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      box-sizing: border-box;
      height: 100%;
      color: #373D3F;
      width: 100%;
      border-radius: 8px;

      &::placeholder {
        //color: #373D3F;
      }

      &_error {
        border: 1px solid red;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;
      cursor: pointer;
      border-top-right-radius: 8px;

      border-bottom-right-radius: 8px;
      background: $blue-dark;

      &_active {
        background: $blue;
      }
    }

    &__dropdown {
      position: absolute;
      z-index: 2000;
      top: 50px;
      display: flex;
      overflow-x: hidden;
      flex-direction: column;
      width: 100%;
      max-height: 200px;
      padding: 6px;
      border-radius: 8px;
      background: $globus-background-secondary-color;
      box-shadow: 1px 2px 10px 0 #131E2726;
      gap: 4px;

      .dropdown-item {
        padding: 10px;
        cursor: pointer;
        user-select: none;
        border-radius: 8px;

        &:hover {
          background-color: $globus-button-secondary-hover-color;
        }
      }

    }
  }
}
</style>