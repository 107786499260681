import { createRouter, createWebHistory  } from "vue-router";
import LKPage from "../pages/LKPage.vue";
import Login from "../pages/LoginPage.vue";
import { mainStorage } from "@/composables/tools/main-storage.tools";
import {goLkPage, goMainPage} from "@/composables/ModuleCommunication";
import { useStore } from "@/store";
import {useProjects} from "@/store/projects";
import {projectDisabledModules} from "@/composables/constans/modules";
import {useUser} from "@/store/user";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/lk",
    name: "lk",
    component: LKPage
  },
];

const history = createWebHistory();

const router = createRouter({
  history: history,
  routes
});

router.beforeEach(async (to, from, next) => {
  const store = useStore();
  const userStore = useUser();

  if (to.name === "login") {
    if (mainStorage.getItem("access_token")) {
      goMainPage();
    }
  }

  if (!mainStorage.getItem("access_token")) {
    if (to.name !== "login") {
      await router.push("login");
    }
  } else if (to.name === "login") {
    goMainPage();
  }
  else if(to.fullPath === "/" && store.mainHrefApp){
    goMainPage();
    document.body.style.backgroundColor = "#EEF1F4";
  } else if (to.fullPath === "/lk"){
    document.body.style.backgroundColor = "#F4FAFF";
  } else {
    document.body.style.backgroundColor = "#EEF1F4";
  }

  const projectStore = useProjects();

  if (projectStore.activeProject?.modules?.length > 0
      && !projectStore.activeProject?.modules.includes(store.activeModule.dataName)
      && !projectDisabledModules.includes(store.activeModule.dataName)) {
    if (to.name !== "lk") {
      await ProjectHasntModule();
    }
  } else {
    if (window.routerApp) {
      window.routerApp.replace(to);
    }
  }
  next();
});

/** Редирект, если у пользователя отсутствуют проекты */
export const userHasntProjects = async () => {
  const store = useStore();
  store.createNotify("Создайте проект, что бы начать использовать систему", "error");
  await goLkPage();
};


/** Редирект, если у активного проекта нет модуля, в котором сейчас находится пользователь. */
export const ProjectHasntModule = async () => {
  const store = useStore();

  await goLkPage();
  store.isShowProjectNoModuleDialog = true;
};

window.routerLK = router; 
export default router;
