<script setup>
import {t} from "@/controllerLK/GlobusLocalization";
import {computed, defineProps, onMounted, ref} from "vue";
import {useProjects} from "@/store/projects";
import LKProjectWindowSearch from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectWindowSearch.vue";
import LKProjectModal from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectModal.vue";
import LKDeleteProjectModal from "@/pages/LKPage/LKWindow/LKProjectWindow/LKDeleteProjectModal.vue";
import LKProjectCardNew from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectCardNew.vue";
import LKProjectCard from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectCard.vue";

const props = defineProps({
  isArchive: {
    type: Boolean,
    default: false
  },
  projects: {
    type: Object,
    required: true
  }
});

const projectStore = useProjects();
const isEdit = ref(false);
const projectSearchString = ref("");

onMounted(async () => {
  await projectStore.getProjects(props.isArchive);
});

const projectList = computed(() => {
  let projects;

  if (projectSearchString.value.length > 0) {
    projects = props.projects.filter((project) =>
        project.name.toLowerCase().includes(projectSearchString.value.toLowerCase()));
  } else {
    projects = props.projects;
  }

  props.isArchive ? projects = projects.filter((project) => project.isOwner) : false;
  return projects;
});


/* Модальные окна. CRUD **/
const selectedProject = ref(null);

const isShowProjectModal = ref(false);
const projectModalToggle = (project, show, edit = false) => {
  selectedProject.value = project;
  isShowProjectModal.value = show;
  isEdit.value = edit;
};

const isShowDeleteProjectModal = ref(false);
const deleteProjectModalToggle = (project, show) => {
  selectedProject.value = project; 
  isShowDeleteProjectModal.value = show;
};

const getEmptyCardCount = computed(() => {
  if (projectList.value.length === 0) return 3;
  if (projectList.value.length === 1) return 2;
  if (projectList.value.length === 2) return 1;
  return 0;
});
</script>

<template>
  <div class="window-wrapper">
    <div class="header">
      <div class="header__title">
        {{ t('lk_project_window.title') }}
      </div>
      <div class="header__search">
        <LKProjectWindowSearch
          v-model="projectSearchString"
        />
      </div>
    </div>

    <div class="body scroll">
      <LKProjectCardNew
        v-if="!isArchive"
        class="project-card"
        @click="projectModalToggle(null, true, false)"
      />

      <LKProjectCard
        v-for="project in projectList"
        :key="project.id"
        :project="project"
        :is-archive="props.isArchive"
        @edit-project="val => projectModalToggle(val, true, true)"
        @delete-project="val => deleteProjectModalToggle(val, true)"
      />
      <div
        v-for="card in getEmptyCardCount"
        :key="card"
      />
    </div>

    <LKProjectModal
      :show="isShowProjectModal"
      :is-edit="isEdit"
      :is-archive="isArchive"
      :project="selectedProject"
      @close-modal="projectModalToggle(null, false, false)"
      @show-delete-project-modal="isShowDeleteProjectModal = true"
    />

    <LKDeleteProjectModal
      :is-project-owner="selectedProject?.isOwner"
      :project-id="selectedProject?.id"
      :project-name="selectedProject?.name"
      :show="isShowDeleteProjectModal"
      :is-archive="props.isArchive"
      @close-project-modal="isShowProjectModal = false"
      @close-modal="deleteProjectModalToggle(null, false)"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars';

.window-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 33px;

  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    &__title {
      font-size: 28px;
      font-weight: 400;
      text-wrap: nowrap;
    }

    &__search {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    padding-bottom: 30px;
    grid-auto-rows: max-content;
    height: 100%;
  }

  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }
}

</style>
