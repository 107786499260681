export const buttons = [
    {
        name: "settings",
        text: "Профиль и настройки",
    },
    {
        name: "projects",
        text: "Проекты",
    },
    {
        name: "archive",
        text: "Архив",
    },
    {
        name: "exit",
        text: "Выход",
    }
];
