<script setup>
import {defineProps, ref, defineEmits, watch, computed} from "vue";
import { t } from "@/controllerLK/GlobusLocalization";
import {useProjects} from "@/store/projects";
import {createProjectForm} from "@/composables/constans/formData";
import {useStore} from "@/store";
import ProjectModalArchive from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalArchive.vue";
import ProjectModalHeader from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalHeader.vue";
import ProjectModalName from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalName.vue";
import ProjectModalModules from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalModules.vue";
import ProjectModalCountry from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalCountry.vue";
import ProjectModalControl from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalControl.vue";
import BlockView from "@/components/common/BlockView.vue";
import GlobusDialog from "@/components/common/GlobusDialog.vue";
import {useUser} from "@/store/user";

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  isArchive: {
    type: Boolean,
    default: false
  },
  project: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(["closeModal", "updateProjectList", "showDeleteProjectModal"]);

const projectStore = useProjects();
const mainStore = useStore();
const userStore = useUser();

const errorList = ref([]);

const createProjectFormData = ref(JSON.parse(JSON.stringify(createProjectForm)));

watch(() => props.show, async (status) => {
  if (status === true) {
    mapDeletedRegionList.value = [];
    mapNewRegionList.value = [];
    errorList.value = [];

    if (props.isEdit) {
      createProjectFormData.value = JSON.parse(JSON.stringify(props.project));
    } else {
      createProjectFormData.value = JSON.parse(JSON.stringify(createProjectForm));
    }
    isCustomMapUploaded.value = false;
    customMap.value = null;
  }
});

const isHasUnsavedChanges = computed(() => {
  return !(JSON.stringify(createProjectFormData.value) === JSON.stringify(props.project));
});
const isShowUnsavedChangesDialog = ref(false);

const saveUnsavedChanges = () => {
  createProject();
  isShowUnsavedChangesDialog.value = false;
};

// Работа с картами.

const mapDeletedRegionList = ref([]);
const mapNewRegionList = ref([]);
const isCustomMapUploaded = ref(false);
const customMap = ref(null);

const addRegionToList = (region) => {
  if (!region.id) {
    isCustomMapUploaded.value = true;
    customMap.value = region;
  }

  createProjectFormData.value.mapFiles.push(region);
  mapNewRegionList.value.push(region);
};

const removeRegionFromList = (region) => {
  // Если карта уже была в проекте ранее
  if (region.mapFilePath) {
    mapDeletedRegionList.value.push(region);

    const mapIndex = createProjectFormData.value.mapFiles
        .findIndex(mapFile => mapFile.mapFilePath === region.mapFilePath);

    createProjectFormData.value.mapFiles.splice(mapIndex, 1);
  } else {

    if (region.file) {
      isCustomMapUploaded.value = false;
      customMap.value = null;
    }

    const mapIndex = createProjectFormData.value.mapFiles
        .findIndex(mapFile => mapFile.name === region.name);
    mapIndex >= 0 ? createProjectFormData.value.mapFiles.splice(mapIndex, 1) : false;

    const mapNewIndex = mapNewRegionList.value
        .findIndex(mapFile => mapFile.name === region.name);
    mapNewIndex >= 0 ? mapNewRegionList.value.splice(mapNewIndex, 1) : false;
  }


};

// Создание проекта

const isFormSubmitted = ref(false);

const createProject = async () => {

  const projectFormData = JSON.parse(JSON.stringify(createProjectFormData.value));

  isFormSubmitted.value = true;
  errorList.value = [];
  projectFormData.name = projectFormData.name.trim();
  if (projectFormData.name.length === 0) {
    errorList.value.push("noName");
  }

  // eslint-disable-next-line
  const emojiRegEx = new RegExp(/[^\x00-\x7Fа-яА-Я]/g)

  if (projectFormData.name.search(emojiRegEx) !== -1) {
    errorList.value.push("noEmoji");
  }

  const moduleList = projectFormData.modules;
  if (moduleList.length === 0) {
    errorList.value.push("noModule");
  }

  if (moduleList.length === 0) {
    errorList.value.push("noModule");
  }

  if (!errorList.value.length > 0) {
    mainStore.loader = true;
    // TODO: На беке на круд проекта должны убрать параметр mapFiles, после этого удалить строку ниже 24.12.24
    projectFormData.mapFiles = [];
    if (!props.isEdit) {
      const newProject = await projectStore.createProject(projectFormData);
      projectFormData.id = newProject.id;
    } else {
      await projectStore.updateProject(projectFormData);
    }
    //// Пользователи проекта

    await projectStore.updateAccessToProject(projectFormData.id, createProjectFormData.value.users);
    //// Карты
    // Удаление карт
    for (let region of mapDeletedRegionList.value) {
      await projectStore.deleteProjectMap(projectFormData.id, region);
    }
    // Создание карт
    for (let region of mapNewRegionList.value) {
      if (!region.file) {
        await projectStore.createMap(projectFormData.id, region.id, region.name);
      } else {
        await projectStore.uploadMap(projectFormData.id, region.file);
      }
    }
    emit("closeModal");
    await projectStore.getProjects();
    await projectStore.setActiveProject(projectFormData.id);
  }
  mainStore.loader = false;
  isFormSubmitted.value = false;
};

//
const addUserList = (userList) => {
  createProjectFormData.value.users = userList.map((user) => user.userId);
};

const closeProjectModal = () => {
  if (props.isEdit) {
    if (isHasUnsavedChanges.value) {
      isShowUnsavedChangesDialog.value = true;
    } else {
      emit("closeModal");
    }
  } else {
    emit("closeModal");
  }
};

</script>

<template>
  <BlockView
    v-if="props.show"
    @click="closeProjectModal"
  />

  <div
    v-if="props.show"
    class="modal-create-project"
  >
    <ProjectModalHeader
      :is-edit="props.isEdit"
      @close-modal="closeProjectModal"
    />

    <ProjectModalName
      :error-list="errorList"
      :project-name="createProjectFormData.name"
      :project-description="createProjectFormData.description"
      @update:project-name="val => createProjectFormData.name = val"
      @update:project-description="val => createProjectFormData.description = val"
    />

    <ProjectModalArchive
      v-if="props.isEdit && createProjectFormData.isOwner"
      :is-archive-page="props.isArchive"
      :is-archive="createProjectFormData.isArchive"
      @update:is-archive="val => createProjectFormData.isArchive = val"
      @show-delete-project-modal="emit('showDeleteProjectModal')"
      @close-project-modal="emit('closeModal')"
    />
    <ProjectModalModules
      v-if="!mainStore.isProd"
      :error-list="errorList"
      :active-module-list="createProjectFormData.modules"
      @add-module="val => createProjectFormData.modules.push(val)"
      @delete-module="val => createProjectFormData.modules.splice(val, 1)"
    />

    <ProjectModalCountry
      v-if="userStore.user.roleNames.includes('аналитик')"
      :custom-map="customMap"
      :is-custom-map-uploaded="isCustomMapUploaded"
      :map-new-region-list="mapNewRegionList"
      :map-deleted-region-list="mapDeletedRegionList"
      :map-files="createProjectFormData.mapFiles"
      :is-edit="props.isEdit"
      @add-region-to-list="val => addRegionToList(val)"
      @remove-region-from-list="val => removeRegionFromList(val)"
    />

    <ProjectModalControl
      ref="projectModalControlRef"
      :is-form-submitted="isFormSubmitted"
      :is-has-unsaved-changes="isHasUnsavedChanges"
      :is-edit="props.isEdit"
      :project="createProjectFormData"
      @create-project="createProject"
      @update-project-list="emit('updateProjectList')"
      @add-user-list="val => addUserList(val)"
      @close-modal="emit('closeModal')"
    />
  </div>

  <GlobusDialog
    v-if="isShowUnsavedChangesDialog"
    :cancel-button-text="t('lk_project_modal.unsaved_changes.button.cancel')"
    :confirm-button-text="t('lk_project_modal.unsaved_changes.button.save')"
    class="unsaved-changed-dialog"
    :title="t('lk_project_modal.unsaved_changes.title')"
    @confirm="saveUnsavedChanges"
    @cancel="isShowUnsavedChangesDialog = false; emit('closeModal')"
  >
    <div class="unsaved-changed-dialog__text">
      {{ t('lk_project_modal.unsaved_changes.message') }}
    </div>
  </GlobusDialog>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';
@import '@/assets/styles/classes.scss';

.unsaved-changed-dialog {
  z-index: 2001;

  &__text {
    color: #373D3F;
  }
}

.modal-create-project {
  position: fixed;
  z-index: 2001;
  top: 0;
  right: 0;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  width: 555px;
  height: 100%;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px 0 #00000024;
  gap: 20px;
}

</style>