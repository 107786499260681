<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {ref} from "vue";
import {t} from "@/controllerLK/GlobusLocalization";
import CountryComboBox
  from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalCountry/CountryComboBox.vue";
import MapUploadDialog
  from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalCountry/MapUploadDialog.vue";


const emit = defineEmits(["add-region-to-list", "remove-region-from-list"]);

defineProps({
  isEdit: {
    type: Boolean,
    default: false
  },
  mapFiles: {
    type: Array,
    required: true,
  },
  mapNewRegionList: {
    type: Array,
    required: true
  },
  mapDeletedRegionList: {
    type: Array,
    required: true
  },
  isCustomMapUploaded: {
    type: Boolean,
    default: false
  },
  customMap: {
    type: Object,
    default: () => null
  }
});


const isShowMapUploadDialog = ref(false);
const isShowRegionSearch = ref(false);

</script>

<template>
  <div
    class="country-wrapper"
  >
    <div class="country-wrapper__title">
      {{ t('project_modal_country.title') }}
    </div>
    <div class="country-wrapper__control">
      <CountryComboBox
        @add-region-to-list="val => emit('add-region-to-list', val)"
        @close-region-search="isShowRegionSearch = false"
      />
      <div
        v-if="!isCustomMapUploaded && !customMap"
        class="map-upload-button"
        @click="isShowMapUploadDialog = true"
      >
        {{ t('project_modal_country.options.upload') }}
        <SvgIcon
          name="upload-icon-bold"
          width="14px"
          height="14px"
        />
      </div>
      <div
        v-else
        class="map-upload-button"
        @click="emit('remove-region-from-list', customMap)"
      >
        <div class="map-upload-button__text">
          {{ customMap.name }}
        </div>
        <SvgIcon
          class="map-upload-button__icon"
          name="check-green"
          width="14px"
          height="14px"
        />
      </div>
    </div>
    <div class="country-wrapper-list scroll">
      <div
        v-for="country in mapFiles"
        :key="country"
        class="country-wrapper-list__item"
      >
        <div>
          <SvgIcon
            v-if="!country.mapFilePath"
            name="region-check"
            width="14px"
            height="14px"
          />
          {{ country.name }}
        </div>

        <SvgIcon
          class="trash"
          name="trash"
          width="16px"
          height="16px"
          @click="emit('remove-region-from-list', country)"
        />
      </div>
    </div>
  </div>

  <MapUploadDialog
    :is-show-map-upload-dialog="isShowMapUploadDialog"
    @add-region-to-list="val => emit('add-region-to-list', val)"
    @close-map-upload-dialog="isShowMapUploadDialog = false"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';
@import '@/assets/styles/classes.scss';

.country-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  gap: 24px;
  min-height: 0;
  flex-grow: 1;

  &-list {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 14px;
      color: #1F9AFF;
      font-size: 14px;
      font-weight: 400;
      transition: 0.3s ease-in-out;

      .trash {
        cursor: pointer;
        user-select: none;
      }

      &:hover {
        border-radius: 8px;
        background: #E0F0FF;
      }
    }
  }

  &__title {
    color: #212327;
    font-size: 16px;
    font-weight: 400;
  }

  &__control {
    display: flex;
    gap: 10px;

    .map-upload-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 160px;
      padding: 6.4px 15px;
      gap: 6px;
      border-radius: 8px;
      color: #373D3F;
      background: #F4FAFF;
      font-size: 14px;
      cursor: pointer;
      user-select: none;
      font-weight: 500;
      transition: 0.3s all ease-in-out;

      &__text {
        display: inline;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        font-size: 14px;
        font-weight: 500;
        color: #A7A7A7;
      }

      &__icon {
        width: 20px;
        height: 14px;
      }

      &:hover {
        background: #E0F0FF;
      }
    }
  }
}

</style>
