<script setup>
import {defineProps, defineEmits, onMounted, useTemplateRef} from "vue";
import { vMaska } from "maska/vue"; 

const props = defineProps({
  value: {
    type: String,
    default: ""
  },
  width: {
    type: String,
    default: "120px"
  },
  height: {
    type: String,
    default: "40px"
  },
  placeHolder: {
    type: String,
    default: ""
  },
  errorMessage: {
    type: [Boolean, String],
    default: false
  },
  type: {
    type: String,
    default: "text"
  },
  mask: {
    type: String,
    default: null
  },
  useMask: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: null
  },
  maxLength: {
    type: Number,
    default: 60
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:value"]);

const globusInput = useTemplateRef("globusInput");

onMounted(() => {
  // globusInput.value.style.minWidth = props.width
  globusInput.value.style.height = props.height;
});

</script>

<template>
  <div class="input-wrapper">
    <span
      v-if="title"
      class="title"
    >{{ title }}</span>

    <input
      v-if="props.useMask"
      ref="globusInput"
      v-maska:mask="mask"
      :disabled="disabled"
      :maxLength="props.maxLength"
      :value="value"
      :placeholder="props.placeHolder"
      :type="type"
      :class="{
        'border-error': errorMessage
      }"
      @input="emit('update:value', $event.target.value)"
    >
    <input
      v-else
      ref="globusInput"
      v-maska:mask="mask"
      :disabled="disabled"
      :maxLength="props.maxLength"
      :value="value"
      :placeholder="props.placeHolder"
      :type="type"
      :class="{
        'border-error': errorMessage
      }"
      @input="emit('update:value', $event.target.value)"
    >


    <span
      v-if="errorMessage"
      class="error-message"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';
@import "@/assets/styles/classes.scss";

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 40px;

  .title {
    display: flex;
    font-weight: 500;
    font-size: 16px;
  }

  input {
    all: unset;

    color: $globus-background-secondary-text-color;
    background: $light-blue;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
    gap: 4px;
    //transition: all 0.3s ease-in-out;
  }

  input:hover {
    background: $hover-active-color;
  }

  input:focus {
    outline: 1px solid $globe-active-color;
    background: #FFF;
  }

  input:disabled {
    background: $light-blue;
  }

  .error-message {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: red !important;
  }
}
</style>