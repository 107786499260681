import {mainStorage} from "@/composables/tools/main-storage.tools.js";
import {getToken} from "@/composables/auth";
import {changeLoadApp, changeShow} from "@/composables/ModuleCommunication";

/**
 * Инициализирует интерсепторы для экземпляра Axios, добавляя токен авторизации к каждому запросу
 * и обрабатывая ошибки ответов.
 *
 * @param {Object} axios - Экземпляр Axios, к которому будут добавлены интерсепторы.
 * @param {Object} LKStore - Экземпляр хранилища (LKStore), который используется для управления состоянием
 *                           и перенаправления пользователя на страницу логина в случае ошибок авторизации.
 */
export function axiosInit(axios, LKStore) {
    axios.interceptors.request.use(async (config) => {
        if (!config.url.includes("/login") && !config.url.includes("amcharts")) {
            const token = mainStorage.getItem("access_token");
            config.headers.Authorization = "Bearer " + token;
        }
        return config;
    });
    axios.interceptors.response.use(null, async (error) => {
        const status = error?.response?.status;
        changeLoadApp(true);
        changeShow(true);
        if (status === 401 && window.location.pathname !== "/login") {
            const refreshToken = await getToken();

            if (refreshToken === false) {
                LKStore.goLogin();
                changeLoadApp(false);
                changeShow(false);
            } else {
                const originalRequets = error.config;
                delete originalRequets.headers["Authorization"];
                return axios.request(originalRequets);
            }
        } else if (status >= 400 && status < 600 ) {
            throw error;
        }
        else {
            console.log("Возникла ошибка при загрузке данных:", error);
        }
        return error;
    });
}
