<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {useProjects} from "@/store/projects";
import {ref} from "vue";
import BlockView from "@/components/common/BlockView.vue";
import { t } from "@/controllerLK/GlobusLocalization";
import {useStore} from "@/store";
import ProjectCardMenu from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectCard/ProjectCardMenu.vue";

const projectStore = useProjects();

const store = useStore();

const props = defineProps({
  project: {
    type: Object,
    required: true,
    default: () => ({
      id: null,
      name: null,
      modules: [],
      users: []
    })
  },
  isArchive: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["editProject", "deleteProject"]);

/* Отображение меню проекта **/
const isShowProjectMenu = ref(false);

/* Сделать выбранный проект активным **/
const setActiveProject = async (project) => {
  // Нельзя сделать архивный проект активным
  if (props.isArchive) return false;

  // В текущем модуле нельзя сделать активным проект, в котором данный модуль не подключен
  if (!project.modules.includes(store.activeModule.dataName)) {
    const message = `${t("lk_project_card.notify.unable_active")}
     ${t("lk_project_card.modules." + store.activeModule.dataName)}`;
    store.createNotify(message, "error");

    return false;
  }

  store.loader = true;
  await projectStore.setActiveProject(project.id);
  store.loader = false;
};


const coords = ref({
  x: null,
  y: null
});

/* Открыть меню проекта **/
const showProjectMenu = (event) => {
  coords.value.x = event.x;
  coords.value.y = event.y;

  isShowProjectMenu.value = true;
};
</script>

<template>
  <div
    class="project project-card"
    :class="{'project-active': project.id === projectStore.activeProject.id}"
    @click.self="setActiveProject(project)"
  >
    <div
      class="project-module"
      @click="setActiveProject(project)"
    >
      <div
        v-for="(module, key) in project.modules.
          filter((projectModule) => store.getEnabledModuleList().includes(projectModule))"
        :key="module"
        class="project-module__icon"
        :style="{left: `-${7 * key}px`}"
      >
        <SvgIcon
          class="svg-icon"
          width="29px"
          height="29px"
          :name="module"
        />
      </div>
    </div>

    <div class="project-body">
      <div
        class="project-body-title"
        @click="setActiveProject(project)"
      >
        <div
          v-if="project.isOwner"
          class="project-body-title__icon"
        >
          <SvgIcon
            class="svg-icon"
            width="14px"
            height="14px"
            name="userCheck"
          />
        </div>
        <div class="project-body-title__value">
          {{ t('lk_project_card.title') }} {{ project.name }}
        </div>
      </div>
      <div
        class="project-body__button"
        @click="(event) => showProjectMenu(event)"
      >
        <SvgIcon
          width="14px"
          height="14px"
          :name="`burger-mini`"
        />
      </div>
      <ProjectCardMenu
        v-if="isShowProjectMenu"
        :coords="coords"
        :is-archive="isArchive"
        :project="project"
        @delete-project="emit('deleteProject', project)"
        @edit-project="emit('editProject', project)"
        @close-project-menu="isShowProjectMenu = false"
      />
    </div>
    <div
      class="project__member"
      @click.self="setActiveProject(project)"
    >
      {{ t('lk_project_card.members') }}: {{ project.users.length === 0 ? 1 : project.users.length }}
    </div>
  </div>

  <BlockView
    v-if="isShowProjectMenu"
    @click="isShowProjectMenu = false"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars';

.project {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: 130px;
  padding: 20px;
  cursor: pointer;
  border: 1px solid $globe-passive-color;
  border-radius: 8px;
  gap: 8px;
  min-width: 256px;

  &__member {
    color: $globus-background-primary-text-color;
  }

  .project-module {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &__icon {
      position: relative;
      border: 1px solid white;
      border-radius: 50%;
    }
  }

  &:hover {
    border: 1px solid white;
    background: $globus-background-primary-color;
  }

  &-active {
    border-color: $globe-active-color;

    &:hover {
      border: 1px solid $globe-active-color;
    }
  }

  &-body {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-title {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;

      &__icon {
        position: relative;
        width: 14px;
        height: 14px;
        bottom: 1.5px;
      }

      &__value {
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        overflow: hidden;
        width: 200px;
        text-align: start;
        white-space: nowrap;
        text-decoration: none;
        text-overflow: ellipsis;
        color: $text-01;
      }

    }

    &__button {
      z-index: 100;
      width: 14px;
      height: 14px;
    }

    &-menu {
      position: absolute;
      z-index: 2000;
      top: 0;
      left: 95px;
      width: 210px;
      padding: 6px;
      border-radius: 8px;
      background: #FFFFFF;
      box-shadow: 1px 2px 10px 0 #131E2726;

      &__item {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 40px;
        padding-left: 8px;
        border-radius: 8px;
        gap: 4px;

        &:hover {
          background: $hover-active-color;
        }
      }
    }
  }
}
</style>