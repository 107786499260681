<script setup>

import UndefinedPhotoIcon from "@/assets/icons/UndefinedPhotoIcon.vue";
import {useUser} from "@/store/user";
import {ref} from "vue";
import HomeIcon from "@/assets/icons/HomeIcon.vue";
import UserIcon from "@/assets/icons/UserIcon.vue";
import LogoutIcon from "@/assets/icons/LogoutIcon.vue";
import {useRouter} from "vue-router";
import {goLkPage, goMainPage} from "@/composables/ModuleCommunication";
import BlockView from "@/components/common/BlockView.vue";
import { t } from "@/controllerLK/GlobusLocalization";
import {useStore} from "@/store";

const router = useRouter();
const userStore = useUser();
const store = useStore(); 

const isShowDropdown = ref(false);
const isImageLoaded = ref(true);

const moveToSettings = () => {
  localStorage.setItem("lk_tab", "settings");
  goLkPage();
  isShowDropdown.value = false;
};

</script>

<template>
  <div class="profile">
    <div
      class="profile-image"
      @click="isShowDropdown = !isShowDropdown"
    >
      <img
        v-if="userStore.user?.image && isImageLoaded"
        :src="userStore.user.image"
        alt="Avatar"
        class="profile-image__icon"
        @error="isImageLoaded = false"
      >
      <UndefinedPhotoIcon
        v-else
        :class="{'profile-image__icon_map-page': router.currentRoute.value.fullPath === '/map'}"
        class="profile-image__icon"
      />
    </div>
    <div
      v-if="isShowDropdown"
      :class="{'profile-dropdown__home': router.currentRoute.value.fullPath === '/lk'}"
      class="profile-dropdown"
    >
      <div
        v-if="router.currentRoute.value.fullPath === '/lk'"
        class="profile-dropdown__item"
        @click="goMainPage(); isShowDropdown = false;"
      >
        <HomeIcon class="icon" /> {{ t('header_user_profile.main_page') }}
      </div>
      <div
        class="profile-dropdown__item"
        @click="moveToSettings"
      >
        <UserIcon class="icon" />
        {{ userStore.user.userName }}
      </div>
      <div
        class="profile-dropdown__item"
        @click="store.isShowLogoutDialog = true; isShowDropdown = false;"
      >
        <LogoutIcon class="icon" />
        {{ t('header_user_profile.exit') }}
      </div>
    </div>
  </div>
  <BlockView
    v-if="isShowDropdown"
    @click="isShowDropdown = false"
  />
</template>

<style scoped lang="scss">

.profile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  &-dropdown {
    position: absolute;
    width: 140px;
    top: 50px;
    right: 0;
    z-index: 2000;
    box-shadow: 1px 2px 10px 0 #131E2726;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 8px;
    padding: 6px;
    background: #FFF;

    &__home {
      width: 230px;
    }

    &__item {
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 6px 14px;
      gap: 5px;
      border-radius: 8px;
      transition: all .2s ease-in-out;

      &_home {
        width: 250px;
      }

      .icon {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background: #F7F8FD;
      }
    }
  }

  &-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #FFFFFF;

    &__icon {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      object-fit: cover;

      &_map-page {
        background: #F7F8FD;
      }
    }
  }
}

</style>