<script setup>
import {defineEmits, defineProps} from "vue";
import {useProjects} from "@/store/projects";
import { t } from "@/controllerLK/GlobusLocalization";

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  projectName: {
    type: String,
    default: ""
  },
  projectId: {
    type: Number,
    default: null
  },
  isArchive: {
    type: Boolean,
    required: true
  },
  isProjectOwner: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["closeModal", "closeProjectModal"]);

const projectStore = useProjects();

const deleteProject = () => {
  projectStore.deleteProject(props.projectId, props.isArchive, props.isProjectOwner);
  emit("closeModal");
  emit("closeProjectModal");
};
</script>

<template>
  <div
    v-if="props.show"
    class="modal-wrapper"
  >
    <div class="modal-delete-project">
      <div class="modal-delete-project-header">
        {{ t('lk_delete_project_modal.title') }}
      </div>
      <div class="modal-delete-project-body">
        {{ t('lk_delete_project_modal.message') }}: {{ projectName }}?
      </div>
      <div class="modal-delete-project-footer">
        <div
          class="modal-delete-project-footer__button modal-delete-project-footer__close"
          @click="emit('closeModal')"
        >
          {{ t('lk_delete_project_modal.button.cancel') }}
        </div>
        <div
          class="modal-delete-project-footer__button modal-delete-project-footer__delete"
          @click="deleteProject()"
        >
          {{ t('lk_delete_project_modal.button.delete') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 246px;

  transform: translate(-50%, -50%);
  background: #FFFFFF;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
  z-index: 2000;

  .modal-delete-project {
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 100%;

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }

    &-body {
      color: #373D3F;
      text-align: center;
      word-break: break-word;
      overflow-y: hidden;
      line-height: 20px;
      background: $background-color;
      padding: 30px 20px 30px 20px;
      border-radius: 8px;
    }

    &-footer {
      display: flex;
      justify-content: center;
      gap: 14px;
      &__button {
        width: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        font-weight: 500;
        cursor: pointer;
        height: 40px;

      }

      &__close {
        color: #373D3F;
        background-color: $background-color;
      }

      &__close:hover {
        background-color: #EEF1FF;
        color: $globe-active-color;
        font-weight: 500;
      }

      &__delete {
        background-color: #1A64D4;
      }

      &__delete:hover {
        background-color: #1F9AFF;
      }
    }
  }

}
</style>