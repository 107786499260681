import router from "@/router/index";
import { useStore } from "@/store";
import {changeShow} from "@/composables/ModuleCommunication";
import { mainStorage } from "@/composables/tools/main-storage.tools";
import { useUser } from "@/store/user";
import { useProjects } from "@/store/projects";

export async function serverErrorExit() {
    const Store = useStore();
    mainStorage.setItem("access_token", "");
    mainStorage.setItem("access_time_end", 1);
    mainStorage.setItem("refresh_time_end", 1);
    mainStorage.setItem("timeEnd", 1);
    mainStorage.setItem("refresh_token", "");
    changeShow(false);
    Store.isShowLogoutDialog = false;
    clearData();
    await router.push("/login");
}

export async function exit(){
    const Store = useStore();
    mainStorage.setItem("access_token", "");
    mainStorage.setItem("access_time_end", 1);
    mainStorage.setItem("refresh_time_end", 1);
    mainStorage.setItem("timeEnd", 1);
    mainStorage.setItem("refresh_token", "");
    changeShow(false);
    Store.isShowLogoutDialog = false;
    clearData();
    await router.push("/login");
    window.location.reload();
}

export function confirmExit() {
    const Store = useStore();
    Store.confirmExitModalIsActive = true;
}

export function clearData() {
    const userStore = useUser();
    const projectsStore = useProjects(); 
    userStore.clearUser();
    projectsStore.clearData();
}
