<script setup>
import {onBeforeUnmount, onMounted, ref} from "vue";
import SvgButton from "@/components/common/svgButton.vue";
import GlobusButtonPrimary from "@/components/common/GlobusInput/GlobusButtonPrimary.vue";
import GlobusTextInput from "@/components/common/GlobusInput/GlobusTextInput.vue";
import PasswordInput from "@/pages/LoginPage/PasswordInput.vue";
import {useRouter} from "vue-router";
import {LogIn} from "@/composables/auth";
import {useStore} from "@/store";
import {personalAreaInitialize} from "@/composables/PersonalAreaInitialize";
import {t, useGlobusLocal} from "@/controllerLK/GlobusLocalization";
import ChevronDown from "@/assets/icons/ChevronDown.vue";
import ChevronUp from "@/assets/icons/ChevronUp.vue";
import { locales } from "@/controllerLK/GlobusLocalization";
import {goLkPage, goMainPage} from "@/composables/ModuleCommunication";
import {useProjects} from "@/store/projects";
import {useUser} from "@/store/user";
import {trim} from "core-js/internals/string-trim";

const { initGlobusLocal } = useGlobusLocal();

onMounted(() => {
  document.addEventListener("keydown", handleEnterClick);
  getAppVersion();
  let globusLanguage = localStorage.getItem("globusLanguage");

  globusLanguage ? activeLanguage.value = globusLanguage : activeLanguage.value = "ru";
  globusLanguage = activeLanguage.value;
  initGlobusLocal(globusLanguage.toLowerCase(), "personal_area");
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleEnterClick);
});

const getAppVersion = async () => {
  while (LKVersion.value.length === 0 || APPVersion.value.length === 0) {
    LKVersion.value = localStorage.getItem("personalAreaVersion") ? localStorage.getItem("personalAreaVersion") : "";
    APPVersion.value = localStorage.getItem("appVersion") ? localStorage.getItem("appVersion") : "";
    await new Promise(r => setTimeout(r, 2000));
  }
};

const store = useStore();
const userStore = useUser();
const projectStore = useProjects();

const passwordInputRef = ref(null);

const router = useRouter();

const userForm = ref({
  username: "",
  password: ""
});

const isSubmitted = ref(false);
const isUsernameError = ref();
const isPasswordError = ref();

const doLogin = async () => {
  isUsernameError.value = false;
  isPasswordError.value = false;
  isSubmitted.value = true;

  if (userForm.value.username.length === 0) {
    isUsernameError.value = true;
  }

  if (userForm.value.password.length === 0) {
    isPasswordError.value = true;
  }

  if (isUsernameError.value || isPasswordError.value) {
    return false;
  }

  userForm.value.username = trim(userForm.value.username);
  userForm.value.password = trim(userForm.value.password);

  store.loader = true;

  let loginData = await LogIn(userForm.value);

  if (loginData.status) {
    await personalAreaInitialize();
    if (projectStore.projects.length > 0 || userStore.user.activeProject !== 0) {
      goMainPage();
    }

  } else {
    if (loginData.code === 401) {
      store.createNotify(t("login_page.error.invalid"), "error");
    } else {
      store.createNotify(t("login_page.error.server"), "error");
    }
  }
  store.loader = false;
  isSubmitted.value = false;
};

const selectedLanguage = (lang) => {
  isShowLanguageDropdown.value = false;
  if (lang === "ru" || lang === "en") {
    activeLanguage.value = lang;
    localStorage.setItem("globusLanguage", lang);
    store.language.code = lang;
  }
  return false;
};
const activeLanguage = ref(null);
const isShowLanguageDropdown = ref(false);

const LKVersion = ref("");
const APPVersion = ref("");

const handleEnterClick = (event) => {
  if (event.keyCode !== 13) {
    return false;
  } else {
    doLogin();
  }
};

</script>

<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-box__header">
        <div class="language-selector">
          <div
            class="language-selector__selected"
            :class="{'language-selector__selected_active': isShowLanguageDropdown}"
            @click="isShowLanguageDropdown = !isShowLanguageDropdown"
          >
            {{ t('login_page.language.' + activeLanguage) }}
            <ChevronUp v-if="isShowLanguageDropdown" />
            <ChevronDown v-else />
          </div>
          <div
            v-if="isShowLanguageDropdown"
            class="language-selector-dropdown"
          >
            <div
              v-for="lang in locales"
              :key="lang.code"
              class="language-selector-dropdown__item"
              :class="{'language-selector-dropdown__item_active': lang.code === activeLanguage}"
              @click="selectedLanguage(lang.code)"
            >
              <img
                :src="require(`/public/icons/countries/${lang.code}.png`)"
                alt=""
              >
              {{ t('login_page.language.' + lang.code) }}
            </div>
          </div>
        </div>
      </div>
      <div class="login-box__body">
        <form class="login-form">
          <div class="login-form__title">
            {{ t('login_page.modules.' + store.activeModule.dataName) }}
            <SvgButton
              :key="store.activeModule.dataName"
              class="project-header__logo"
              height="24px"
              width="24px"
              :name="store.activeModule.dataName"
            />
          </div>
          <div class="login-form__input">
            <GlobusTextInput
              v-model="userForm.username"
              :is-error="isUsernameError"
              :label="t('login_page.label.login')"
            />
            <PasswordInput
              ref="passwordInputRef"
              v-model="userForm.password"
              :is-error="isPasswordError"
              :label="t('login_page.label.password')"
            />
          </div>
          <div class="login-form__button">
            <GlobusButtonPrimary @click="doLogin">
              {{ t('login_page.button.accept') }}
            </GlobusButtonPrimary>
          </div>
        </form>
      </div>
    </div>
    <div class="version">
      {{ t('login_page.label.version') }}: {{ LKVersion }} - {{ APPVersion }}
    </div>
  </div>
</template>

<style scoped lang="scss">

.version {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF33;
}

.login-page {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #09518C;
  line-height: 1;
}

.login-box {
  display: flex;
  flex-direction: column;

  width: 580px;
  height: 480px;
  padding: 20px 30px;
  border-radius: 20px;
  background: #FFF;
  gap: 80px;

  font-size: 14px;
  font-weight: 400;

  &__header {
    display: flex;
    justify-content: flex-end;

    .language-selector {

      position: relative;

      &__selected {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        user-select: none;
        color: #898989;
        &_active {
          color: #1F9AFF;
        }
      }



      &-dropdown {
        top: calc(100% + 10px);
        right: 0;
        border-radius: 8px;
        padding: 6px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        background: #FFF;
        color: #212327;

        box-shadow: 1px 2px 10px 0 #131E2726;
        position: absolute;

        &__item {
          display: flex;
          align-items: center;
          gap: 4px;
          border-radius: 8px;
          cursor: pointer;
          user-select: none;
          padding: 6px 14px;

          img {
            width: 21px;
            height: 14px;
          }

          &_active {
            background: #E0F0FF;
          }

          &:hover {
            background: #EEF1F4;
          }
        }
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;

    .login-form {
      display: flex;
      flex-direction: column;
      width: 70%;
      gap: 28px;

      &__input {
        display: flex;
        flex-direction: column;
        gap: 28px;
      }

      &__title {
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        color: #000;
        gap: 10px;
      }

      &__button {
        width: 110px;
      }
    }
  }
}
</style>