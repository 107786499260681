<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1586_4871)">
      <path
        d="M9.3335 12.25V11.0833C9.3335 10.4645 9.08766 9.871 8.65008 9.43342C8.21249 8.99583 7.619 8.75 7.00016 8.75H2.91683C2.29799 8.75 1.7045 8.99583 1.26691 9.43342C0.829329 9.871 0.583496 10.4645 0.583496 11.0833V12.25"
        stroke="#A7A7A7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.95833 6.41667C6.247 6.41667 7.29167 5.372 7.29167 4.08333C7.29167 2.79467 6.247 1.75 4.95833 1.75C3.66967 1.75 2.625 2.79467 2.625 4.08333C2.625 5.372 3.66967 6.41667 4.95833 6.41667Z"
        stroke="#A7A7A7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.9165 6.41667L11.0832 7.58333L13.4165 5.25"
        stroke="#A7A7A7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1586_4871">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>