<template>
  <div
    class="globus-header"
    :class="{'lk-page-background': router.currentRoute.value.fullPath === '/lk'}"
  >
    <HeaderProject />
    <HeaderMenu />
    <LogoutDialog
      :show="store.isShowLogoutDialog"
      @close-dialog="store.isShowLogoutDialog = false"
    />
  </div>
</template>

<script setup>
import HeaderProject from "@/components/GlobusHeader/HeaderProject.vue";
import LogoutDialog from "@/components/GlobusHeader/LogoutDialog.vue";
import HeaderMenu from "@/components/GlobusHeader/HeaderMenu.vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {useUser} from "@/store/user";

const userStore = useUser();
const store = useStore();
const router = useRouter();
</script>

<style lang="scss" scoped>
@import '@/assets/styles/styles.scss';

.globus-header {
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  padding-top: 14px;
  //margin-top: 10px;
  padding-bottom: 14px;
}

.lk-page-background {
  background: #F4FAFF;
}
</style>