<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {onMounted} from "vue";
import {useStore} from "@/store";

const store = useStore();

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  setTimeout(() => {
    deleteNotification();
  }, props.item.lifeTime);
}); 

const deleteNotification = () => {
  let index =  store.notifications.findIndex(it => it.message === props.item.message);
  store.notifications.splice(index, 1);
};

</script>

<template>
  <div
    class="notification-item"
    @click="deleteNotification"
  >
    <div class="notification-item__icon">
      <SvgIcon
        v-if="item.type === 'success'"
        name="accept-icon"
        width="44px"
        height="44px"
      />
      <SvgIcon
        v-if="item.type === 'error'"
        name="warning-icon"
        width="50px"
        height="50px"
      />
    </div>

    <div class="notification-item__message">
      {{ item.message }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.notification-item {
  font-size: 22px;
  font-weight: 400;
  display: flex;
  align-items: center;
  max-width: 500px;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  color: #FFFFFF;
  border-radius: 20px;
  background: #1F9AFF;
  gap: 20px;

  &__message {
    font-size: 22px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    //word-break: break-all;
  }
}
</style>